import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "求职者",
    component: () => import(/* webpackChunkName: "main" */ "../views/HomeView.vue"),
  },
  {
    path: "/login",
    name: "登录",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/denglu.vue"),
  },
  {
    path: "/lead",
    name: "总管页面",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "master" */ "../views/LeadGroup.vue"),
  },
  {
    path: "/lead_batch",
    name: "总管批量处理",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "master" */ "../views/LeadBatch.vue"),
  },
  {
    path: "/result",
    name: "结果页面",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "result" */ "../views/result.vue"),
  },
  {
    path: "/cmaster",
    name: "区域主管",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "bmaster" */ "../views/cMasterCompany.vue"),
  },
  {
    path: "/cAdmin",
    name: "公司管理",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "companyadmin" */ "../views/CompanyAdmin.vue"),
  },
  {
    path: "/dashboard",
    name: "后台",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
  },
  {
    path: "/dashboard/charts",
    name: "汇总",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dashboard" */ "../views/charts.vue"),
  },
  {
    path: "/changeRequest",
    name: "修改个人信息确认",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "request" */ "../views/changeRequest.vue"),
  },
  {
    path: "/inviterMain",
    name: "招聘员主页",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "inviter" */ "../views/inviter.vue"),
  },
  {
    path: "/inviterAdmin",
    name: "招聘员设置",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "inviter" */ "../views/inviterAdmin.vue"),
  },
  {
    path: "/sign",
    name: "签名",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "sign" */ "../views/sign.vue"),
  },
  {
    path: "/loan",
    name: "借支",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "loan" */ "../views/loan/index.vue"),
  },
  {
    path: "/loanAdmin",
    name: "借支管理",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "loan" */ "../views/loan/admin.vue"),
  },
  {
    path: "/loan/:id",
    name: "借支订单",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "loan" */ "../views/loan/info.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});
export default router;
